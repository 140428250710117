import styled from "styled-components"
import { Link, useLoaderData } from "react-router-dom"
import { Button } from "@mui/material"

import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle"
import { Container } from "../../../components/Container"
import { PackagingUnit } from "../../../components/packaging/PackagingUnit"
import { CollectionHistory } from "../../../components/history/CollectionHistory";
import { useState } from "react"
import { Paper } from "../../../components/Paper"
import { SortingCenter } from "../component/SortingCenter"
import { GenericPackaging } from "../../../components/packaging/GenericPackaging"

const PackagingUnitsContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap : 20px;
    margin-top:40px
`

const ContentContainer = styled.div`
    padding:20px;
`

const TopWrapper = styled.div`
    display:flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`


export const CollectPoint = () => {
    const { collectPoint } = useLoaderData() as any
    console.log('here is my cp', collectPoint)
    const [units, setUnits] = useState(collectPoint.storageCrates);
    const [collectionHistories, setCollectionHistories] = useState(collectPoint.collectionHistories)
    return (
        <Container>
            <BackButtonAndTitle title={collectPoint.name} backLink={"/"} />

            <ContentContainer>
                <TopWrapper>
                    <div style={{ flex: 1 }}>
                        <p style={{ marginBottom: 10 }}>{collectPoint.location.address}</p>
                        <Link to={`/points-de-collecte/${collectPoint.id}/modifier`}>
                            <Button variant="contained" style={{ marginRight: 15 }}>Modifier</Button>
                        </Link>
                        <Link to={`/points-de-collecte/${collectPoint.id}/assigner-nouvelles-unites`}>
                            <Button variant="outlined">Lier unité d'emballage</Button>
                        </Link>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h3>Centres de tri liés</h3>
                        <Paper style={{ padding: 10, maxHeight: 150, overflow: "scroll" }}>
                            {collectPoint.sortingCenters.map((sortingCenter: any) => <SortingCenter center={sortingCenter} isOwner={sortingCenter.id === collectPoint.owner.id} />)}
                        </Paper>
                        <Link to={`/points-de-collecte/${collectPoint.id}/inviter-centre-de-tri`}>
                            Inviter un centre de tri
                        </Link>
                    </div>
                </TopWrapper>

                <h3 style={{ marginTop: 20 }}>Historique</h3>
                <Paper style={{ padding: 10, maxHeight: 150, overflow: "scroll" }}>
                    {collectionHistories.sort((a: any, b: any) => {
                        return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                    }).map((h: any) => {
                        return (
                            <CollectionHistory history={h} setUnits={setUnits} collectionHistories={collectionHistories} setCollectionHistories={setCollectionHistories} />
                        )
                    })}
                </Paper>
            </ContentContainer>

            <PackagingUnitsContainer>
                {collectPoint.genericPackagingStocks.map((genericPackaging: any) => {
                    return (
                        <GenericPackaging genericPackaging={genericPackaging} />
                    )
                })}
                {units.map((unit: any) => {
                    return (
                        <PackagingUnit unit={unit} currentCollectPointId={collectPoint.id} setUnits={setUnits} units={units} setCollectionHistories={setCollectionHistories} />
                    )
                })}
            </PackagingUnitsContainer>
        </Container>
    )
}