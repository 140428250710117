import { CustomModal } from "../../components/CustomModal"
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useMutation } from "@apollo/client";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useNavigate } from "react-router-dom";
import { loader } from 'graphql.macro';
import { Loader } from "../../components/Loader";
import { ErrorMessage } from "../../components/ErrorMessage";

import { TextFieldContainer } from "../../components/form/TextFieldContainer";

const CREATE_PACKAGING = loader('../../graphql/packaging/mutation/createPackaging.graphql');

export const CreatePackagingModal = (props: any) => {
    const [createPackaging, { loading, error, data }] = useMutation(CREATE_PACKAGING);

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            name: "",
            level: props.packagingTypes.level[0],
            type: props.packagingTypes.type[0],
            width: null,
            height: null,
            depth: null,
            weight: null,
            bottleMeanCapacity: null
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            level: Yup.string().required('Champ requis'),
            weight: Yup.number().required('Champ requis'),
            width: Yup.number().required('Champ requis'),
            height: Yup.number().required('Champ requis'),
            depth: Yup.number().required('Champ requis'),
            bottleMeanCapacity: Yup.number().positive().nullable()
        }),
        onSubmit: async values => {
            console.log('submitting form', values)
            await createPackaging({
                variables: values
            })
            // if success, close modal and clear formik form
            navigate("/emballages")
            props.setOpenModal(false)
            formik.resetForm()
        }
    })

    console.log("formik", formik)
    console.log(formik.handleSubmit)

    return (
        <CustomModal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <h2>Créer un emballage</h2>
            <form onSubmit={formik.handleSubmit}>
                <TextFieldContainer>
                    <InputLabel>Nom *</InputLabel>
                    <TextField
                        id="name"
                        label="Nom de l'emballage *"
                        variant="outlined"
                        fullWidth
                        {...formik.getFieldProps('name')}
                        error={formik.touched.name && formik.errors.name ? true : false}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </TextFieldContainer>


                <TextFieldContainer>
                    <InputLabel>Niveau *</InputLabel>
                    <Select fullWidth {...formik.getFieldProps('level')}>
                        {props.packagingTypes.level.map((level: any) => {
                            return (
                                <MenuItem value={level}>{level}</MenuItem>
                            )
                        })
                        }
                    </Select>
                </TextFieldContainer>

                <TextFieldContainer>
                    <InputLabel>Type *</InputLabel>
                    <Select fullWidth {...formik.getFieldProps('type')}>
                        {props.packagingTypes.type.map((type: any) => {
                            return (
                                <MenuItem value={type}>{type}</MenuItem>
                            )
                        })
                        }
                    </Select>
                </TextFieldContainer>

                <TextFieldContainer>
                    <InputLabel>Largeur *</InputLabel>
                    <TextField
                        id="width"
                        label="Largeur (mm)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        {...formik.getFieldProps('width')}
                    />
                </TextFieldContainer>

                <TextFieldContainer>
                    <InputLabel>Hauteur *</InputLabel>
                    <TextField
                        id="height"
                        label="Hauteur (mm)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        {...formik.getFieldProps('height')}
                    />
                </TextFieldContainer>

                <TextFieldContainer>
                    <InputLabel>Profondeur *</InputLabel>
                    <TextField
                        id="depth"
                        label="Profondeur (mm)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        {...formik.getFieldProps('depth')}
                    />
                </TextFieldContainer>

                <InputLabel>Poids *</InputLabel>
                <TextField
                    id="weight"
                    label="Poids (g)"
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...formik.getFieldProps('weight')}
                />

                <InputLabel>Capacité moyenne de bouteilles</InputLabel>
                <TextField
                    id="bottleMeanCapacity"
                    label="Capacité moyenne de bouteilles"
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...formik.getFieldProps('bottleMeanCapacity')}
                />

                {!loading && <Button variant="contained" style={{ marginTop: 20 }} type="submit">Créer l'emballage</Button>}
                {loading && <Loader />}
                {error && <ErrorMessage>Une errreur est survenue...</ErrorMessage>}
            </form>
        </CustomModal>
    )
}