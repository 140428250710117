import styled from 'styled-components';
import { Props } from '../types/Props';
export const PaperStyled = styled.div`
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.1);
    @media (max-width: 768px) {
        width: 100%;
    }

`;

export const Paper = (props: Props) => {
    return (
        <PaperStyled style={props.style}>
            {props.children}
        </PaperStyled>
    )
}