import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

export const apolloClient = new ApolloClient({
    uri: process.env.NODE_ENV === "production" ? 'https://back-office.swiv.fr/graphql' : 'http://localhost:4000/graphql',
    cache: new InMemoryCache(),
    headers: {
        authorization: localStorage.getItem('jwt') || '',
    },
    defaultOptions: {
        // disable query caching
        query: {
            fetchPolicy: 'no-cache',
        },
    }
});