import { Switch } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ErrorMessage } from "../ErrorMessage";
import { useEffect, useState } from "react";
import { Loader } from "../Loader";

const IS_ACTIVATE = gql`
    query($packagingId: Float!){
        isGenericTrackingActivated(packagingId: $packagingId)
    }
`

const CHANGE_ACTIVATION = gql`
    mutation($isActivate: Boolean!, $packagingId: Float!){
        changeGenericTrackingBool(isActivate: $isActivate, packagingId: $packagingId)
    }
`;

export const ActivateGenericTracking = ({ packagingId }: { packagingId: number }) => {
    const [isActivate, setIsActivate] = useState(false);
    const [changeActivation, changeActivationResponse] = useMutation(CHANGE_ACTIVATION);
    const { data, loading, error } = useQuery(IS_ACTIVATE, {
        variables: { packagingId }
    });

    useEffect(() => {
        if (data) {
            console.log(data.isGenericTrackingActivated)
            setIsActivate(data.isGenericTrackingActivated);
        }
    }, [data]);

    const handleChangeActivation = async () => {
        // ASK with a popup if user is sure, if yes then continue else break
        if (isActivate) {
            const confirmActivation = window.confirm("Êtes-vous sûr de vouloir supprimer le suivi générique ? Cela entrainera la perte du suivi générique pour tous les points de collecte.");

            if (!confirmActivation) {
                return;
            }
        }

        await changeActivation({
            variables: {
                isActivate: !isActivate,
                packagingId
            },
            onCompleted: (data) => {
                setIsActivate(data.changeGenericTrackingBool);
            }
        });
    }


    if (error) {
        return <ErrorMessage>Une erreur s'est produite lors du chargement de l'activation du suivi générique</ErrorMessage>
    }
    return (
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                Activer le suivi générique
            </p>
            {!loading && !changeActivationResponse.loading &&
                <Switch
                    onChange={handleChangeActivation}
                    checked={isActivate}
                />}
            {
                (changeActivationResponse.loading || loading) && <Loader />
            }
        </div>
    )
};