import styled from "styled-components"
import { useEffect, useState } from "react"
import { Paper } from "../Paper"
import { gql, useMutation } from "@apollo/client"
import { Loader } from "../Loader"
import { ErrorMessage } from "../ErrorMessage"

const StockLine = styled.div`
 margin-top:20px;
 @media (max-width: 768px) {
    display:flex;
     width: 100%;
     justify-content: space-between;
    align-items: center;
    }
`

const PlusMinusButton = styled.button`
    padding:20px;
    border:1px solid black;
    background-color:white;
    cursor:pointer;
    &:hover{
        background-color:lightgray;
    }
    border-radius:5px;
`

const UPDATE_STOCK = gql`
    mutation($genericPackagingStockId: Float!, $emptyStock: Float, $filledStock: Float){
        updateGenericPackagingStock(genericPackagingStockId: $genericPackagingStockId,emptyStock: $emptyStock,filledStock: $filledStock) {
            id
            emptyStock
            filledStock
        }
    }
`

export const GenericPackaging = ({ genericPackaging }: { genericPackaging: { id: number, emptyStock: number, filledStock: number, packaging: { name: string } } }) => {
    const [emptyStock, setEmptyStock] = useState(genericPackaging.emptyStock)
    const [filledStock, setFilledStock] = useState(genericPackaging.filledStock)
    const [updateGenericPackagingStock, { loading, error }] = useMutation(UPDATE_STOCK,
        {
            onCompleted: (data) => {
                setEmptyStock(data.updateGenericPackagingStock.emptyStock)
                setFilledStock(data.updateGenericPackagingStock.filledStock)
            }
        }
    )

    const updateStock = async (stock: string, newValue: number) => {
        if (newValue < 0) return
        await updateGenericPackagingStock({
            variables: {
                genericPackagingStockId: genericPackaging.id,
                emptyStock: stock === "empty" ? newValue : emptyStock,
                filledStock: stock === "filled" ? newValue : filledStock
            }
        })
    }

    return (
        <Paper style={{ padding: 10, minWidth: 250 }}>
            <h3>{genericPackaging.packaging.name}</h3>
            <StockLine>
                <p style={{ fontWeight: "600", marginBottom: 5 }}>Stock vide</p>
                <div style={{ display: "flex", gap: 15, alignItems: "center", fontWeight: "600" }}>
                    <PlusMinusButton
                        onClick={() => updateStock("empty", emptyStock - 1)}
                        disabled={emptyStock === 0 || loading}
                    >-</PlusMinusButton>
                    {loading ? <Loader /> : emptyStock}
                    <PlusMinusButton
                        onClick={() => updateStock("empty", emptyStock + 1)}
                        disabled={loading}
                    >+</PlusMinusButton>
                </div>
            </StockLine>
            <StockLine>
                <p style={{ fontWeight: "600", marginBottom: 5 }}>Stock plein</p>
                <div style={{ display: "flex", gap: 15, alignItems: "center", fontWeight: "600" }}>
                    <PlusMinusButton
                        onClick={() => updateStock("filled", filledStock - 1)}
                        disabled={filledStock === 0 || loading}
                    >-</PlusMinusButton>
                    {loading ? <Loader /> : filledStock}
                    <PlusMinusButton
                        onClick={() => updateStock("filled", filledStock + 1)}
                        disabled={loading}
                    >+</PlusMinusButton>
                </div>
            </StockLine>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </Paper>
    )
}